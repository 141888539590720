import { useTranslation, Trans } from 'react-i18next';

import { Text } from 'components/common/text/text';
import { BlockWrapper } from './block.styles';

const Block = ({ title, transKey, strong }) => {
  const { t } = useTranslation();

  return (
    <BlockWrapper>
      <Text weight="bold" size="lg">
        {t(title)}
      </Text>

      <Text>
        <Trans i18nKey={transKey}>
          <strong>{strong}</strong>
        </Trans>
      </Text>
    </BlockWrapper>
  );
};

export default Block;
