import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;

  @media ${themeGet('mediaQueries.tabletPortraitUp')} {
    flex-direction: row;
  }
`;

export const Block = styled.div`
  display: flex;
  flex-direction: column;

  padding: 20px;
  gap: 20px;

  p:first-of-type {
    line-height: 1.21;
  }

  @media ${themeGet('mediaQueries.tabletPortraitUp')} {
    flex-basis: 50%;
  }

  @media ${themeGet('mediaQueries.tabletLandscapeUp')} {
    padding: 60px;
  }
`;

export const BlockWithBorder = styled(Block)`
  justify-content: space-between;

  box-shadow: ${themeGet('shadows.section')};
  border-radius: ${themeGet('radii.base')};

  a {
    color: ${themeGet('colors.text.light')};
  }

  button {
    margin-top: 20px;
  }
`;
