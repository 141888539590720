import { useTranslation } from 'react-i18next';

import SEO from 'components/seo';
import { Container, MainContentArea, Row } from 'assets/styles/pages.style';
import Top from 'components/staking/top/top';
import Features from 'components/staking/features/features';
import Bottom from 'components/staking/bottom/bottom';

export default function StakingPage() {
  return (
    <MainContentArea pt={[3, 6]}>
      <Container>
        <Row mb={[5]}>
          <Top />
        </Row>
        <Row mb={[5]}>
          <Features />
        </Row>
        <Row mb={[5]}>
          <Bottom />
        </Row>
      </Container>
    </MainContentArea>
  );
}

export const Head = () => {
  const { t } = useTranslation();
  return <SEO title={t('pageTitles.staking.title')} />;
};
