const DistributedIcon = () => {
  return (
    <svg
      width="68"
      height="48"
      viewBox="0 0 68 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M49.0013 24.0013C53.6013 24.0013 57.3013 20.268 57.3013 15.668C57.3013 11.068 53.6013 7.33464 49.0013 7.33464C44.4013 7.33464 40.668 11.068 40.668 15.668C40.668 20.268 44.4013 24.0013 49.0013 24.0013ZM24.0013 20.668C29.5346 20.668 33.968 16.2013 33.968 10.668C33.968 5.13464 29.5346 0.667969 24.0013 0.667969C18.468 0.667969 14.0013 5.13464 14.0013 10.668C14.0013 16.2013 18.468 20.668 24.0013 20.668ZM49.0013 30.668C42.9013 30.668 30.668 33.7346 30.668 39.8346V44.0013C30.668 45.8346 32.168 47.3346 34.0013 47.3346H64.0013C65.8346 47.3346 67.3346 45.8346 67.3346 44.0013V39.8346C67.3346 33.7346 55.1013 30.668 49.0013 30.668ZM24.0013 27.3346C16.2346 27.3346 0.667969 31.2346 0.667969 39.0013V44.0013C0.667969 45.8346 2.16797 47.3346 4.0013 47.3346H24.0013V39.8346C24.0013 37.0013 25.1013 32.0346 31.9013 28.268C29.0013 27.668 26.2013 27.3346 24.0013 27.3346Z"
        fill="#6487FF"
      />
    </svg>
  );
};

export default DistributedIcon;
