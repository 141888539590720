import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;

  @media ${themeGet('mediaQueries.tabletPortraitUp')} {
    flex-direction: row;

    & > div {
      flex-basis: 50%;
    }
  }
`;
