import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';

export const List = styled.ul`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 20px;

  padding: 50px 60px;

  box-shadow: ${themeGet('shadows.section')};
  border-radius: ${themeGet('radii.base')};
`;

export const Container = styled.li`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  p {
    margin-top: 30px;
  }
`;
