const DevotedIcon = () => {
  return (
    <svg
      width="54"
      height="61"
      viewBox="0 0 54 61"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M23.6669 19.5664C26.3003 19.5664 28.4336 21.6997 28.4336 24.3331C28.4336 26.9664 26.3003 29.0997 23.6669 29.0997C21.0336 29.0997 18.9003 26.9664 18.9003 24.3331C18.9003 21.6997 21.0336 19.5664 23.6669 19.5664Z"
        fill="#6487FF"
      />
      <path
        d="M22.966 0.998115C35.7659 0.631448 46.2993 10.5648 46.9326 23.1314L53.3326 31.6648C54.1659 32.7648 53.3659 34.3314 51.9993 34.3314H46.9993V44.3314C46.9993 47.9981 43.9993 50.9981 40.3326 50.9981H36.9993V57.6648C36.9993 59.4981 35.4993 60.9981 33.666 60.9981H16.9993C15.166 60.9981 13.666 59.4981 13.666 57.6648V45.3981C5.53262 41.5314 -0.000717163 33.1314 0.332619 23.4648C0.799286 11.3981 10.9326 1.36478 22.966 0.998115ZM13.666 24.3314C13.666 24.7648 13.6993 25.1981 13.7326 25.6314L10.966 27.8314C10.6993 28.0314 10.6326 28.3648 10.7993 28.6648L13.466 33.2981C13.6326 33.5981 13.9993 33.6981 14.266 33.5981L17.566 32.2648C18.2659 32.7981 18.9993 33.2314 19.7993 33.5648L20.3326 37.0981C20.366 37.4314 20.666 37.6648 20.9993 37.6648H26.3326C26.666 37.6648 26.9326 37.4314 26.9993 37.0981L27.4993 33.5648C28.3326 33.2314 29.066 32.7981 29.7659 32.2648L33.0659 33.5981C33.3659 33.6981 33.7326 33.5981 33.8993 33.2981L36.566 28.6648C36.7326 28.3981 36.6659 28.0314 36.3993 27.8314L33.5993 25.6314C33.6326 25.1981 33.666 24.7648 33.666 24.3314C33.666 23.8981 33.5993 23.4314 33.5326 23.0314L36.366 20.8314C36.6326 20.6314 36.6993 20.2981 36.5326 19.9648L33.866 15.3648C33.6993 15.0648 33.3659 14.9648 33.0659 15.0648L29.7326 16.3981C29.0659 15.8981 28.2993 15.4314 27.4993 15.0981L26.9993 11.5648C26.9326 11.2314 26.666 10.9981 26.3326 10.9981H20.9993C20.666 10.9981 20.3993 11.2314 20.3326 11.5648L19.8326 15.0981C19.0326 15.4314 18.2993 15.8648 17.5993 16.3981L14.266 15.0648C13.966 14.9648 13.5993 15.0648 13.466 15.3648L10.7993 19.9648C10.6326 20.2648 10.6993 20.6314 10.966 20.8314L13.7993 23.0314C13.6993 23.4314 13.666 23.8648 13.666 24.3314Z"
        fill="#6487FF"
      />
    </svg>
  );
};

export default DevotedIcon;
