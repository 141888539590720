const TransparentIcon = () => {
  return (
    <svg
      width="75"
      height="65"
      viewBox="0 0 75 65"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M40.734 1.86591L22.2673 20.3326C21.034 21.5659 20.334 23.2659 20.334 25.0326V58.3326C20.334 61.9992 23.334 64.9992 27.0007 64.9992H57.0006C59.6673 64.9992 62.0673 63.3993 63.134 60.9659L74.0006 35.5992C76.8007 28.9992 71.9673 21.6659 64.8007 21.6659H45.9673L49.134 6.39925C49.4673 4.73258 48.9673 3.03258 47.7673 1.83258C45.8006 -0.100755 42.6673 -0.100755 40.734 1.86591ZM7.00065 64.9992C10.6673 64.9992 13.6673 61.9992 13.6673 58.3326V31.6659C13.6673 27.9992 10.6673 24.9992 7.00065 24.9992C3.33398 24.9992 0.333984 27.9992 0.333984 31.6659V58.3326C0.333984 61.9992 3.33398 64.9992 7.00065 64.9992Z"
        fill="#6487FF"
      />
    </svg>
  );
};

export default TransparentIcon;
