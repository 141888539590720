const ReliableIcon = () => {
  return (
    <svg
      width="60"
      height="60"
      viewBox="0 0 60 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M46.6667 26.6672C47.8 26.6672 48.9 26.8005 50 26.9672V15.2672C50 12.6005 48.4333 10.2005 46 9.16719L27.6667 1.16719C25.9667 0.433854 24.0333 0.433854 22.3333 1.16719L4 9.16719C1.56667 10.2339 0 12.6339 0 15.2672V27.2672C0 42.4005 10.6667 56.5672 25 60.0005C26.8333 59.5672 28.6 58.9339 30.3333 58.1672C28.0333 54.9005 26.6667 50.9339 26.6667 46.6672C26.6667 35.6339 35.6333 26.6672 46.6667 26.6672Z"
        fill="#6487FF"
      />
      <path
        d="M46.6673 33.332C39.3007 33.332 33.334 39.2987 33.334 46.6654C33.334 54.032 39.3007 59.9987 46.6673 59.9987C54.034 59.9987 60.0006 54.032 60.0006 46.6654C60.0006 39.2987 54.034 33.332 46.6673 33.332ZM46.6673 37.932C48.734 37.932 50.4007 39.632 50.4007 41.6654C50.4007 43.6987 48.7006 45.3987 46.6673 45.3987C44.634 45.3987 42.934 43.6987 42.934 41.6654C42.934 39.632 44.6006 37.932 46.6673 37.932ZM46.6673 55.832C43.5673 55.832 40.8673 54.2987 39.2007 51.932C39.3673 49.532 44.234 48.332 46.6673 48.332C49.1006 48.332 53.9673 49.532 54.134 51.932C52.4673 54.2987 49.7673 55.832 46.6673 55.832Z"
        fill="#6487FF"
      />
    </svg>
  );
};

export default ReliableIcon;
