import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';

export const BlockWrapper = styled.div`
  padding: 20px;

  p:first-of-type {
    margin-bottom: 20px;
  }

  strong {
    font-weight: ${themeGet('fontWeights.bold')};
  }

  @media ${themeGet('mediaQueries.tabletPortraitUp')} {
    padding: 60px;
  }
`;
