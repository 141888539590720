import { useTranslation } from 'react-i18next';

import { POSIcon } from 'components/icons/staking';
import { Text } from 'components/common/text/text';
import { Button } from 'components/common/button/button';
import { Container, Block, BlockWithBorder } from './top.styles';

const Top = () => {
  const { t } = useTranslation();

  return (
    <Container>
      <Block>
        <POSIcon />
        <Text weight="bold" size="lg">
          {t('staking.title')}
        </Text>
        <Text>{t('staking.purpose')}</Text>
      </Block>

      <BlockWithBorder>
        <Text size="lg" weight="bold" color="primary.regular">
          {t('staking.getOnBoard')}
        </Text>
        <Text>{t('staking.applyToOurTeam')}</Text>
        <Button fullwidth>
          <a href="mailto:support@minerall.io">{t('staking.apply')}</a>
        </Button>
      </BlockWithBorder>
    </Container>
  );
};

export default Top;
