import { useTranslation } from 'react-i18next';

import { Text } from 'components/common/text/text';
import { List, Container } from './features.styles';
import { features } from './features.data';

const Features = () => {
  const { t } = useTranslation();

  return (
    <List>
      {features.map(({ title, icon }) => (
        <Container key={title}>
          {icon}
          <Text weight="bold">{t(`staking.features.${title}`)}</Text>
        </Container>
      ))}
    </List>
  );
};

export default Features;
