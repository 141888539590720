const POSIcon = () => {
  return (
    <svg
      width="112"
      height="112"
      viewBox="0 0 112 112"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M82.25 40.25V84L45.5 106.75L38.5 102.417L35 100.251L8.75 84V40.25L45.5 17.5L82.25 40.25Z"
        fill="#6487FF"
      />
      <path
        d="M82.25 40.25L45.5 63L29.9469 53.3728L26.6809 51.3494L8.75 40.25L45.5 17.5L82.25 40.25Z"
        fill="#D3DCFF"
      />
      <path d="M45.5 106.75V63L82.25 40.25V84L45.5 106.75Z" fill="#97ADFF" />
      <path
        d="M64.75 40.25L45.5 52.5L38.6597 48.1469L35.3566 46.0447L26.25 40.25L45.5 28L64.75 40.25Z"
        fill="#6487FF"
      />
      <path
        d="M105 87.5V77H98.9477C98.6386 76.0198 98.2431 75.069 97.766 74.1587L102.049 69.8756L94.6244 62.451L90.3413 66.734C89.431 66.2569 88.4802 65.8614 87.5 65.5523V59.5H77V65.5523C76.0198 65.8614 75.069 66.2569 74.1587 66.734L69.8756 62.451L62.451 69.8756L66.734 74.1587C66.2569 75.069 65.8614 76.0198 65.5523 77H59.5V87.5H65.5523C65.8614 88.4802 66.2569 89.431 66.734 90.3413L62.451 94.6244L69.8756 102.049L74.1587 97.766C75.069 98.2431 76.0198 98.6386 77 98.9477V105H87.5V98.9477C88.4802 98.6386 89.431 98.2431 90.3413 97.766L94.6244 102.049L102.049 94.6244L97.766 90.3413C98.2431 89.431 98.6386 88.4802 98.9477 87.5H105ZM82.25 91C80.5194 91 78.8277 90.4868 77.3888 89.5254C75.9498 88.5639 74.8283 87.1973 74.1661 85.5985C73.5038 83.9996 73.3305 82.2403 73.6681 80.543C74.0057 78.8456 74.8391 77.2865 76.0628 76.0628C77.2865 74.8391 78.8456 74.0057 80.543 73.6681C82.2403 73.3305 83.9996 73.5038 85.5985 74.1661C87.1973 74.8283 88.5639 75.9498 89.5254 77.3888C90.4868 78.8277 91 80.5194 91 82.25C91 84.5706 90.0781 86.7962 88.4372 88.4372C86.7962 90.0781 84.5706 91 82.25 91Z"
        fill="#D3DCFF"
      />
      <path
        d="M91.0014 40.2488H87.5014V34.3304L77.9688 29.564L79.534 26.4336L90.034 31.6836C90.3247 31.8289 90.5691 32.0523 90.74 32.3288C90.9109 32.6052 91.0014 32.9238 91.0014 33.2488V40.2488Z"
        fill="#97ADFF"
      />
      <path d="M87.5 43.75H91V54.25H87.5V43.75Z" fill="#97ADFF" />
      <path
        d="M98 33.25H94.5V29.0816L84.9674 24.3152C84.6767 24.1699 84.4322 23.9465 84.2614 23.67C84.0905 23.3936 84 23.075 84 22.75V15.75H87.5V21.6684L97.0326 26.4348C97.3233 26.5801 97.5678 26.8035 97.7386 27.08C97.9095 27.3564 98 27.675 98 28V33.25Z"
        fill="#97ADFF"
      />
      <path d="M94.5 36.75H98V56H94.5V36.75Z" fill="#97ADFF" />
      <path d="M101.5 24.5H105V61.25H101.5V24.5Z" fill="#97ADFF" />
      <path d="M101.5 12.25H105V21H101.5V12.25Z" fill="#97ADFF" />
      <path
        d="M38.5 89.2483V102.415L35 100.249V90.2392L27.0987 85.4989C26.8398 85.3433 26.6255 85.1233 26.4768 84.8603C26.3281 84.5974 26.2499 84.3004 26.25 83.9983V76.2392L18.3487 71.4989C18.0898 71.3433 17.8755 71.1233 17.7268 70.8603C17.5781 70.5974 17.4999 70.3004 17.5 69.9983V57.7483C17.5002 57.4386 17.5825 57.1345 17.7386 56.867C17.8947 56.5995 18.1189 56.3781 18.3885 56.2256C18.658 56.073 18.9632 55.9947 19.2728 55.9986C19.5825 56.0025 19.8856 56.0884 20.1513 56.2477L26.25 59.9073V52.4983C26.25 52.0753 26.4031 51.6666 26.6809 51.3477L29.9469 53.3711L29.75 53.4892V62.9983C29.7498 63.308 29.6675 63.6121 29.5114 63.8796C29.3553 64.1471 29.1311 64.3684 28.8615 64.521C28.592 64.6735 28.2868 64.7518 27.9772 64.748C27.6675 64.7441 27.3644 64.6581 27.0987 64.4989L21 60.8392V69.0073L28.9012 73.7477C29.1602 73.9033 29.3745 74.1232 29.5232 74.3862C29.672 74.6492 29.7501 74.9462 29.75 75.2483V83.0073L37.6512 87.7477C37.9102 87.9033 38.1245 88.1232 38.2732 88.3862C38.422 88.6492 38.5001 88.9462 38.5 89.2483Z"
        fill="#466BE7"
      />
      <path
        d="M38.6604 48.1452L29.9476 53.3711L26.6816 51.3477C26.8017 51.2098 26.9427 51.0917 27.0995 50.9977L35.3573 46.043L38.6604 48.1452Z"
        fill="#6487FF"
      />
      <path
        d="M77 29.75C78.933 29.75 80.5 28.183 80.5 26.25C80.5 24.317 78.933 22.75 77 22.75C75.067 22.75 73.5 24.317 73.5 26.25C73.5 28.183 75.067 29.75 77 29.75Z"
        fill="#97ADFF"
      />
      <path
        d="M77 31.5C75.9617 31.5 74.9466 31.1921 74.0833 30.6152C73.2199 30.0383 72.547 29.2184 72.1496 28.2591C71.7523 27.2998 71.6483 26.2442 71.8509 25.2258C72.0535 24.2074 72.5535 23.2719 73.2877 22.5377C74.0219 21.8035 74.9574 21.3035 75.9758 21.1009C76.9942 20.8983 78.0498 21.0023 79.0091 21.3996C79.9684 21.797 80.7883 22.4699 81.3652 23.3333C81.9421 24.1966 82.25 25.2116 82.25 26.25C82.2485 27.6419 81.6948 28.9764 80.7106 29.9606C79.7264 30.9448 78.3919 31.4984 77 31.5ZM77 24.5C76.6539 24.5 76.3155 24.6026 76.0278 24.7949C75.74 24.9872 75.5157 25.2605 75.3832 25.5803C75.2508 25.9001 75.2161 26.2519 75.2836 26.5914C75.3512 26.9309 75.5178 27.2427 75.7626 27.4874C76.0073 27.7322 76.3191 27.8989 76.6586 27.9664C76.9981 28.0339 77.3499 27.9992 77.6697 27.8668C77.9895 27.7343 78.2628 27.51 78.4551 27.2222C78.6474 26.9345 78.75 26.5961 78.75 26.25C78.7495 25.786 78.5649 25.3412 78.2369 25.0132C77.9088 24.6851 77.464 24.5005 77 24.5Z"
        fill="#D3DCFF"
      />
      <path
        d="M85.75 15.75C87.683 15.75 89.25 14.183 89.25 12.25C89.25 10.317 87.683 8.75 85.75 8.75C83.817 8.75 82.25 10.317 82.25 12.25C82.25 14.183 83.817 15.75 85.75 15.75Z"
        fill="#97ADFF"
      />
      <path
        d="M85.75 17.5C84.7117 17.5 83.6966 17.1921 82.8333 16.6152C81.9699 16.0383 81.297 15.2184 80.8996 14.2591C80.5023 13.2998 80.3983 12.2442 80.6009 11.2258C80.8035 10.2074 81.3035 9.27192 82.0377 8.53769C82.7719 7.80347 83.7074 7.30345 84.7258 7.10088C85.7442 6.89831 86.7998 7.00227 87.7591 7.39963C88.7184 7.79699 89.5383 8.4699 90.1152 9.33326C90.6921 10.1966 91 11.2116 91 12.25C90.9985 13.6419 90.4448 14.9764 89.4606 15.9606C88.4764 16.9448 87.1419 17.4984 85.75 17.5ZM85.75 10.5C85.4039 10.5 85.0655 10.6026 84.7778 10.7949C84.49 10.9872 84.2657 11.2605 84.1332 11.5803C84.0008 11.9001 83.9661 12.2519 84.0336 12.5914C84.1012 12.9309 84.2678 13.2427 84.5126 13.4874C84.7573 13.7322 85.0691 13.8989 85.4086 13.9664C85.7481 14.0339 86.0999 13.9992 86.4197 13.8668C86.7395 13.7343 87.0128 13.51 87.2051 13.2222C87.3974 12.9345 87.5 12.5961 87.5 12.25C87.4995 11.786 87.3149 11.3412 86.9869 11.0132C86.6588 10.6851 86.214 10.5005 85.75 10.5Z"
        fill="#D3DCFF"
      />
      <path
        d="M103.25 12.25C105.183 12.25 106.75 10.683 106.75 8.75C106.75 6.817 105.183 5.25 103.25 5.25C101.317 5.25 99.75 6.817 99.75 8.75C99.75 10.683 101.317 12.25 103.25 12.25Z"
        fill="#97ADFF"
      />
      <path
        d="M103.25 14C102.212 14 101.197 13.6921 100.333 13.1152C99.4699 12.5383 98.797 11.7184 98.3996 10.7591C98.0023 9.79978 97.8983 8.74418 98.1009 7.72578C98.3035 6.70738 98.8035 5.77192 99.5377 5.03769C100.272 4.30347 101.207 3.80345 102.226 3.60088C103.244 3.39831 104.3 3.50227 105.259 3.89963C106.218 4.29699 107.038 4.9699 107.615 5.83326C108.192 6.69662 108.5 7.71165 108.5 8.75C108.498 10.1419 107.945 11.4764 106.961 12.4606C105.976 13.4448 104.642 13.9984 103.25 14ZM103.25 7C102.904 7 102.566 7.10264 102.278 7.29493C101.99 7.48722 101.766 7.76054 101.633 8.08031C101.501 8.40008 101.466 8.75194 101.534 9.09141C101.601 9.43088 101.768 9.7427 102.013 9.98744C102.257 10.2322 102.569 10.3989 102.909 10.4664C103.248 10.5339 103.6 10.4992 103.92 10.3668C104.239 10.2343 104.513 10.01 104.705 9.72225C104.897 9.43446 105 9.09612 105 8.75C104.999 8.28604 104.815 7.84122 104.487 7.51315C104.159 7.18508 103.714 7.00053 103.25 7Z"
        fill="#D3DCFF"
      />
    </svg>
  );
};

export default POSIcon;
