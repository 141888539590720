import Block from './block/block';
import { Container } from './bottom.styles';

const Bottom = () => {
  return (
    <Container>
      <Block
        title="staking.PoS.title"
        transKey="staking.PoS.description"
        strong="PoS"
      />
      <Block
        title="staking.dPoS.title"
        transKey="staking.dPoS.description"
        strong="DPoS"
      />
    </Container>
  );
};

export default Bottom;
