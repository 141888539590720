import {
  TransparentIcon,
  DevotedIcon,
  SecureIcon,
  ReliableIcon,
  DistributedIcon
} from 'components/icons/staking';

export const features = [
  {
    title: 'transparent',
    icon: <TransparentIcon />
  },
  {
    title: 'devoted',
    icon: <DevotedIcon />
  },
  {
    title: 'secure',
    icon: <SecureIcon />
  },
  {
    title: 'reliable',
    icon: <ReliableIcon />
  },
  {
    title: 'distributed',
    icon: <DistributedIcon />
  }
];
